import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { defer, find } from 'lodash-es';
import { delay, first, skip } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
  ExtendedChartMetadata,
  ExtendedEditablePlaceholderMetadata,
  ExtendedPlaceholderMetadata,
  InsertCreationParams,
  PinValues,
  SelectedData,
  TextPlaceholder,
  VariablePlaceholder,
} from '@core/model';
import { GAService, Global, InlineEditVariablesService } from '@shared/services';
import { INSERT_TYPE } from '@core/enums';
import { InsertConfig } from '@shared/models';
import { InsertContentService } from './insert-content.service';
import { ChartComponent } from './../chart/chart.component';
import { InsertContentDirective } from '@shared/pipes/insert-content.pipe';
import { ComponentsRefers, InlineVarRef } from './insert-content.model';
import { InlineEditableVariableComponent } from './inline-editable-variable/inline-editable-variable.component';

@UntilDestroy()
@Component({
  selector: 'ep-insert-content',
  templateUrl: './insert-content.component.html',
  styleUrls: ['./insert-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsertContentComponent implements OnChanges, OnInit, OnDestroy {
  @Input() body: string;
  @Input() inserts: ExtendedPlaceholderMetadata[];
  @Input() isCompiledPagePreview = false;
  @Input() isActiveBulkEdit = false;

  @Output() chartIsReady = new EventEmitter();
  @Output() pinMove = new EventEmitter();
  @Output() openInsertEditingModal = new EventEmitter<ExtendedPlaceholderMetadata>();
  @Output() customButtonClick = new EventEmitter<string>();
  @Output() insertDropdownSelected = new EventEmitter<InsertConfig[]>();
  @Output() insertProductSelected = new EventEmitter<InsertConfig[]>();
  @Output() insertTabSelected = new EventEmitter<InsertConfig[]>();
  @Output() inlineVariablesChanged = new EventEmitter<InsertConfig[]>();
  @Output() updateDependentPageConfig = new EventEmitter<InsertConfig[]>();
  @Output() deactivateBulkEdit = new EventEmitter<boolean>();

  @ViewChild(InsertContentDirective) set innerContent(content: InsertContentDirective) {
    if (content) {
      this.innerContentRef = content;
      this.insertComponents();
    }
  }

  @HostListener('document:mousedown', ['$event']) public onClick(event: MouseEvent) {
    const isBulkEditBtns = event.target['parentElement']?.classList?.contains('btn-bulk-edit');
    const isBulkEditBtn = event.target['classList']?.contains('btn-bulk-edit');
    const isConfirmSubmit = event.target['classList']?.contains('btn-confirm-submit');
    const isConfirmCancel = event.target['classList']?.contains('btn-confirm-cancel');

    if (
      !this.elementRef.nativeElement.contains(event.target) &&
      this.isActiveBulkEdit &&
      !isBulkEditBtns &&
      !isBulkEditBtn &&
      !isConfirmSubmit &&
      !isConfirmCancel
    ) {
      this.deactivateBulkEdit.emit();
    }
  }

  data: ExtendedPlaceholderMetadata[];
  innerContentRef: InsertContentDirective;

  private componentsRefs: ComponentRef<ComponentsRefers>[] = [];
  private clickListenerByInsertId: Record<string, (e) => void> = {};

  constructor(
    public cdr: ChangeDetectorRef,
    public insertContentService: InsertContentService,
    public global: Global,
    public gaService: GAService,
    private inlineEditVariablesService: InlineEditVariablesService,
    private elementRef: ElementRef
  ) {}

  ngOnChanges(): void {
    this.componentsRefs = [];
    this.innerContentRef && defer(this.insertComponents.bind(this));
    this.insertContentService.setIsActiveBulkEdit = this.isActiveBulkEdit;
  }

  ngOnInit(): void {
    this.watchForSaveInlineEditedVariables();
  }

  ngOnDestroy(): void {
    this.componentsRefs.forEach(ref => {
      ref.instance instanceof ChartComponent && ref.instance?.ngOnDestroy();
      ref.destroy();
    });
  }

  private insertComponents(): void {
    this.global
      .waitImages(this.innerContentRef.viewContainerRef.element)
      .pipe(first(), untilDestroyed(this))
      .subscribe(() => {
        if (this.inserts?.length) {
          // TODO: wrong interface
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          this.inserts.forEach(this.createComponent.bind(this));
          !this.inserts.some(
            insert => (insert as ExtendedPlaceholderMetadata<ExtendedChartMetadata>).isChartPlaceholder
          ) && this.chartIsReadyHandler(true);
        } else if (this.inserts?.length === 0) {
          this.chartIsReadyHandler(true);
        }
      });
  }

  private createComponent(data: InsertCreationParams): void {
    if (
      data.editable &&
      data.insertType !== INSERT_TYPE.dropdown &&
      data.insertType !== INSERT_TYPE.productSelector &&
      data.insertType !== INSERT_TYPE.tab &&
      !data.isInlineEditable
    ) {
      this.insertContentService.createEditablePlaceholderMarkup(data);
      this.handleEditablePlaceHolder(data);
    } else if (data.isInlineEditable && !this.isCompiledPagePreview) {
      this.createInlineEditableMarkup(data);
    }

    switch (data.insertType) {
      case INSERT_TYPE.chart:
        this.createChartInsert(data);
        break;
      case INSERT_TYPE.customTable:
        this.createCustomTableInsert(data);
        break;
      case INSERT_TYPE.button:
        this.createButtonInsert(data);
        break;
      case INSERT_TYPE.dropdown:
        this.createDropDownInsert(data);
        break;
      case INSERT_TYPE.productSelector:
        this.createProductSelectorInsert(data);
        break;
      case INSERT_TYPE.tab:
        this.createTabInsertComponent(data);
        break;
    }
  }

  private handleEditablePlaceHolder(data: ExtendedEditablePlaceholderMetadata): void {
    const insertVarInfo = data.insertType !== INSERT_TYPE.variable || (data as VariablePlaceholder).hiddenOnSharedView;

    if ((this.global.isSharedPresentation() && insertVarInfo) || this.global.isPresentationViewRoute()) {
      return;
    }

    data.elementRef.classList.add('editable-placeholder');

    if (!data.listened) {
      this.clickListenerByInsertId[data.id] = e => {
        e.stopPropagation();
        const insert = find(this.inserts, { id: data.id });
        this.openInsertEditingModal.emit(insert);

        if (insert.insertType === INSERT_TYPE.variable && !this.isCompiledPagePreview) {
          this.gaService.sendInsertEvent({
            elementName: (insert as ExtendedPlaceholderMetadata<VariablePlaceholder>).placeholderName,
            eventAction: 'Variable Insert Clicked',
          });
        }

        if (insert.insertType === INSERT_TYPE.text && !this.isCompiledPagePreview) {
          this.gaService.sendInsertEvent({
            elementName: (insert as ExtendedPlaceholderMetadata<TextPlaceholder>).placeholderName,
            eventAction: 'Text Insert Clicked',
          });
        }
      };
      data.elementRef.addEventListener('click', this.clickListenerByInsertId[data.id]);
      data.listened = true;
    }
  }

  private handleClickablePlaceHolder(data: ExtendedEditablePlaceholderMetadata): void {
    if (!data.listened) {
      this.clickListenerByInsertId[data.id] = e => {
        e.stopPropagation();
        this.customButtonClick.emit(data.id);

        if (INSERT_TYPE.button === data.insertType && !this.isCompiledPagePreview) {
          this.gaService.sendInsertEvent({
            elementName: data.placeholderName,
            eventAction: 'Button Insert Clicked',
          });
        }
      };
      data.elementRef.addEventListener('click', this.clickListenerByInsertId[data.id]);
      data.listened = true;
    }
  }

  private handleDropdownChanges(data: SelectedData): void {
    if (!this.isCompiledPagePreview) {
      this.insertDropdownSelected.emit([data.value]);
      this.gaService.sendInsertEvent({
        elementName: data.name,
        eventAction: 'Dropdown Insert Value Selected',
      });
    }
  }

  private handleProductSelectorChanges(data: SelectedData): void {
    if (!this.isCompiledPagePreview) {
      this.insertProductSelected.emit([data.value]);
      this.gaService.sendInsertEvent({
        elementName: data.name,
        eventAction: 'Product Selector Value Selected',
      });
    }
  }

  private handleTabInsertChanges(data: SelectedData): void {
    if (!this.isCompiledPagePreview) {
      this.insertTabSelected.emit([data.value]);
      this.gaService.sendInsertEvent({
        elementName: data.name,
        eventAction: 'Tab Value Selected',
      });
    }
  }

  private chartIsReadyHandler(event: PinValues[] | boolean): void {
    this.chartIsReady.emit(event);
  }

  private pinMoveHandler(event: PinValues[]): void {
    this.pinMove.emit(event);
  }

  private createProductSelectorInsert(data: InsertCreationParams): void {
    const componentRef = this.insertContentService.createProductSelectorComponent(data);

    componentRef.instance.currentValueChange
      .pipe(untilDestroyed(this), delay(200))
      .subscribe((data: SelectedData) => this.handleProductSelectorChanges(data));

    this.componentsRefs.push(componentRef);
  }

  private createDropDownInsert(data: InsertCreationParams): void {
    const isShared = this.global.isSharedPresentation();
    const isView = this.global.isPresentationViewRoute();

    if ((isShared || isView) && (data as any).hiddenOnSharedView) {
      this.insertContentService.createEditablePlaceholderMarkup(data);

      return;
    }

    const componentRef = this.insertContentService.createDropdownComponent(data);
    componentRef.instance.currentValueChange
      .pipe(untilDestroyed(this), delay(200))
      .subscribe((data: SelectedData) => this.handleDropdownChanges(data));
    this.componentsRefs.push(componentRef);
  }

  private createButtonInsert(data: InsertCreationParams): void {
    this.insertContentService.createButtonComponent(data);
    this.handleClickablePlaceHolder(data);
  }

  private createCustomTableInsert(data: InsertCreationParams): void {
    const componentRef = this.insertContentService.createCustomTableComponent(data);
    this.componentsRefs.push(componentRef);
  }

  private createChartInsert(data: InsertCreationParams): void {
    const componentRef = this.insertContentService.createChartComponent(data);

    data.rendered = !!componentRef;

    componentRef.instance.chartIsReady.pipe(untilDestroyed(this)).subscribe(this.chartIsReadyHandler.bind(this));
    componentRef.instance.pinMove.pipe(skip(1), untilDestroyed(this)).subscribe(this.pinMoveHandler.bind(this));
    componentRef.instance.pinValueChange.pipe(untilDestroyed(this)).subscribe(() => {
      if (!this.isCompiledPagePreview) {
        this.gaService.sendPinChangedEvent(data.chartName);
      }
    });

    this.componentsRefs.push(componentRef);
  }

  private createTabInsertComponent(data: InsertCreationParams): void {
    const componentRef = this.insertContentService.createTabInsertComponent(data);

    componentRef.instance.currentValueChange
      .pipe(untilDestroyed(this), delay(200))
      .subscribe((data: SelectedData) => this.handleTabInsertChanges(data));
    this.componentsRefs.push(componentRef);
  }

  private createInlineEditableMarkup(data: InsertCreationParams): void {
    const componentRef = this.insertContentService.createInlineEditableMarkup(data);

    componentRef.instance.currentValueChange
      .pipe(untilDestroyed(this), delay(200))
      .subscribe((data: InsertConfig[]) => {
        if (!this.isCompiledPagePreview) {
          this.inlineVariablesChanged.emit(data);
        }
      });

    componentRef.instance.updateDependentPageConfig
      .pipe(untilDestroyed(this), delay(200))
      .subscribe((data: InsertConfig[]) => {
        if (!this.isCompiledPagePreview) {
          this.updateDependentPageConfig.emit(data);
        }
      });

    this.componentsRefs.push(componentRef);
  }

  private watchForSaveInlineEditedVariables(): void {
    this.inlineEditVariablesService
      .watchForEmit()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const components: ComponentRef<ComponentsRefers>[] = this.componentsRefs.filter(
          ref => ref.instance instanceof InlineEditableVariableComponent
        );

        components.forEach(ref => this.asVarRef(ref).instance.validateControl());

        const variablesWithErrors = components.filter(ref => {
          const { pattern, max, min, maxlength } = this.asVarRef(ref).instance.formControl?.errors || {};

          return pattern || max || min || maxlength;
        });

        if (variablesWithErrors.length) {
          variablesWithErrors[0].location.nativeElement?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        } else {
          const values = components.map(ref => {
            return {
              uiId: this.asVarRef(ref).instance.metadata.id,
              value: this.asVarRef(ref).instance.formControl.value,
            };
          });

          this.inlineVariablesChanged.emit(values);
        }
      });
  }

  private asVarRef(ref): InlineVarRef {
    return ref as InlineVarRef;
  }
}
