import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, ValidatorFn } from '@angular/forms';

import { ExtendedPlaceholderMetadata, InsertVariableValueType, VariablePlaceholder } from '@core/model';
import { InsertVariableValueTypes } from '@core/enums';
import { getValidatorsForNumberValue, getValidatorsForTextValue } from '@shared/utils';
import { GAService } from '@shared/services';
import { InsertConfig } from '@shared/models';
import { isDefined } from '@core/utils';

@Component({
  selector: 'ep-inline-editable-variable',
  templateUrl: './inline-editable-variable.component.html',
  styleUrls: ['./inline-editable-variable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InlineEditableVariableComponent implements OnInit {
  @Input() metadata: ExtendedPlaceholderMetadata<VariablePlaceholder>;
  @Input() isActiveBulkEdit: boolean;

  @Output() currentValueChange = new EventEmitter<InsertConfig[]>();
  @Output() updateDependentPageConfig = new EventEmitter<InsertConfig[]>();

  valueType = InsertVariableValueTypes;
  formControl: FormControl;
  isFocused = false;
  currentValue: string | number;
  mask: string;
  isNumeric: boolean;

  private isText: boolean;

  constructor(private cdr: ChangeDetectorRef, private gaService: GAService) {}

  ngOnInit(): void {
    this.isNumeric = this.isNumericPlaceholder(this.metadata.placeholderValueType);
    this.isText = this.isTextPlaceholder(this.metadata.placeholderValueType);
    this.createMask();
    this.currentValue = this.metadata.value;

    if (this.isActiveBulkEdit) {
      this.showControl();
    }
  }

  @HostListener('document:mousedown', ['$event']) public onClick(event) {
    const isBtnSubmit = event?.target['classList'].contains('save-inline-variable');

    if (isBtnSubmit && this.formControl?.errors) {
      this.validateControl();
      event.preventDefault();

      return;
    }
  }

  showControl(): void {
    this.formControl = new FormControl(this.currentValue, { validators: this.getValidators() });
    this.isFocused = true;
    this.gaService.sendInsertEvent({
      elementName: this.metadata.placeholderName,
      eventAction: 'Inline Variable Insert Clicked',
    });
    this.cdr.detectChanges();
  }

  hideControl(event): void {
    if (event?.relatedTarget?.classList?.contains('save-inline-variable') || this.isActiveBulkEdit) {
      return;
    }

    this.isFocused = false;
    this.formControl.reset();
    this.cdr.detectChanges();
  }

  save(): void {
    const { pattern, max, min, maxlength } = this.formControl.errors || {};

    if (pattern || max || min || maxlength) {
      return;
    }

    this.currentValue = this.formControl.value;

    const data = {
      uiId: this.metadata.id,
      value: this.currentValue && this.isNumeric ? Number(this.currentValue) : this.currentValue,
    };

    if (this.metadata.isVariableUpdateTriggersCompilation) {
      this.currentValueChange.emit([data]);
    } else {
      this.updateDependentPageConfig.emit([data]);
    }

    this.hideControl(null);
  }

  validateControl(): void {
    this.formControl.updateValueAndValidity();
    this.cdr.detectChanges();
  }

  private getValidators(): ValidatorFn[] {
    if (this.isNumeric) {
      return getValidatorsForNumberValue(this.metadata);
    } else if (this.isText) {
      return getValidatorsForTextValue(this.metadata.placeholderMaxLength);
    }
  }

  private isNumericPlaceholder(type: InsertVariableValueType): boolean {
    return type === this.valueType.number || type === this.valueType.percentage || type === this.valueType.currency;
  }

  private isTextPlaceholder(type: InsertVariableValueType): boolean {
    return type === this.valueType.textArea || type === this.valueType.textField;
  }

  private createMask(): void {
    const isDecimal = isDefined<number>(this.metadata.placeholderDecimals) && this.metadata.placeholderDecimals > 0;
    let mask = isDecimal ? '0*.' : '0*';

    if (this.isNumeric && isDecimal) {
      for (let i = 1; i <= this.metadata.placeholderDecimals; i++) {
        mask += '0';
      }
    }

    this.mask = mask;
  }
}
