import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InsertContentComponent } from './insert-content.component';
import { AssurencePipesModule } from '@shared/pipes/assurence-pipes.module';
import { InsertContentService } from './insert-content.service';
import { CustomTableComponent } from './custom-table/custom-table.component';
import { ChartModule } from './../chart/chart.module';
import { InsertDropdownComponent } from './insert-dropdown/insert-dropdown.component';
import { ThousandsSeparatorService, ImageHandlerService, InlineEditVariablesService } from '@shared/services';
import { ProductSelectorComponent } from './product-selector/product-selector.component';
import { TabInsertComponent } from './tab-insert/tab-insert.component';
import { InlineEditableVariableComponent } from './inline-editable-variable/inline-editable-variable.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '@shared/directives/directives.module';
import { ButtonsModule } from '@se/common';
import { DisableTabIndexDirective } from '@shared/components/insert-content/disable-tab-index.directive';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  declarations: [
    InsertContentComponent,
    CustomTableComponent,
    InsertDropdownComponent,
    ProductSelectorComponent,
    TabInsertComponent,
    InlineEditableVariableComponent,
    DisableTabIndexDirective,
  ],
  imports: [
    CommonModule,
    ChartModule,
    AssurencePipesModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule,
    ButtonsModule,
    NgxMaskModule.forRoot(),
  ],
  exports: [InsertContentComponent],
  providers: [InsertContentService, ImageHandlerService, ThousandsSeparatorService, InlineEditVariablesService],
})
export class InsertContentModule {}
